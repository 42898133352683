import { debounce } from 'debounce'
import * as mobx from 'mobx'
import React from 'react'
import { unstable_LowPriority, unstable_runWithPriority } from 'scheduler'

import { getIn, toPath } from './utils'
import { XField, XFieldFullName, XFieldRegistry, XFieldValidator, XFormState } from './xform.types'
import { TValidationError, TValidationSchema, yup } from './yup'

interface IOptions<TValues> {
  formState: XFormState<TValues>
  fieldRegistry: XFieldRegistry
  validateOnChange: boolean
  validateOnTouched: boolean
  validationSchema?: TValidationSchema
  lowPriorityDebounceIntervalMs: number
  ignoreUnknownFieldsInValidation: boolean
}

export function useFormValidation<TValues>({
  formState,
  fieldRegistry,
  validationSchema,
  validateOnTouched,
  validateOnChange,
  lowPriorityDebounceIntervalMs,
  ignoreUnknownFieldsInValidation,
}: IOptions<TValues>) {
  const changeDisposers = React.useRef<Record<XFieldFullName, mobx.Lambda>>({})
  const pendingValidations = React.useRef(new Set<XFieldFullName>())
  const fieldValidators = React.useRef(
    new Map<XFieldFullName, XFieldValidator<any>[]>(),
  )

  const validateField = React.useCallback(
    async (fullName: XFieldFullName) => {
      const validators = fieldValidators.current.get(fullName)
      if (!validators) {
        return
      }

      const value = getIn(formState.values, toPath(fullName))
      const errors = await Promise.all(validators.map(fn => fn(value)))
      const firstFoundError = errors.find(Boolean)
      if (firstFoundError) {
        formState.errors.set(fullName, firstFoundError)
      } else {
        formState.errors.delete(fullName)
      }
    },
    [formState],
  )

  const validateForm = React.useCallback(async () => {
    const fields = Array.from(fieldRegistry.keys())
    formState.errors.clear()
    formState.isValidating = true
    await Promise.all(fields.map(validateField)).finally(() => {
      formState.isValidating = false
    })
  }, [fieldRegistry, formState, validateField])

  const createFieldValidators = React.useCallback(
    (field: XField) => {
      const validators: XFieldValidator<any>[] = []

      const reg = fieldRegistry.get(field.fullName)
      if (reg && reg.validate) {
        validators.push(reg.validate)
      }

      if (validationSchema) {
        const schemaValidator = getFieldValidator(
          validationSchema,
          field,
          formState,
          ignoreUnknownFieldsInValidation,
        )
        if (schemaValidator) {
          validators.push(schemaValidator)
        }
      }

      return validators
    },
    [
      fieldRegistry,
      formState,
      ignoreUnknownFieldsInValidation,
      validationSchema,
    ],
  )

  const lowPriorityValidation = React.useCallback(() => {
    if (!formState.validationEnabled) {
      return
    }
    unstable_runWithPriority(unstable_LowPriority, () => {
      const pendingArray = Array.from(pendingValidations.current)
      pendingValidations.current.clear()

      formState.isValidating = true
      Promise.all(pendingArray.map(validateField)).finally(() => {
        formState.isValidating = false
      })
    })
  }, [formState, validateField])

  const debouncedLowPriorityValidation = React.useCallback(
    debounce(lowPriorityValidation, lowPriorityDebounceIntervalMs),
    [lowPriorityValidation, lowPriorityDebounceIntervalMs],
  )

  const enqueueFieldForValidation = React.useCallback(
    (fullName: XFieldFullName) => {
      pendingValidations.current.add(fullName)
      debouncedLowPriorityValidation()
    },
    [debouncedLowPriorityValidation],
  )

  const addFieldForValidation = React.useCallback(
    (field: XField) => {
      const validators = createFieldValidators(field)
      if (validators.length === 0) {
        return
      }

      fieldValidators.current.set(field.fullName, validators)

      if (validateOnChange) {
        const disposeChangeReaction = mobx.reaction(
          () => getIn(formState.values, field.parentPath)[field.baseName],
          () => enqueueFieldForValidation(field.fullName),
          { name: `xfield-change-${field.fullName}` },
        )
        changeDisposers.current[field.fullName] = disposeChangeReaction
      }

      enqueueFieldForValidation(field.fullName) // to validate field initially
    },
    [
      createFieldValidators,
      enqueueFieldForValidation,
      formState,
      validateOnChange,
    ],
  )

  const removeFieldFromValidation = React.useCallback((field: XField) => {
    for (const fullName of pendingValidations.current) {
      if (field.fullName === fullName) {
        pendingValidations.current.delete(field.fullName)
      }
    }
    if (changeDisposers.current[field.fullName]) {
      changeDisposers.current[field.fullName]()
      delete changeDisposers.current[field.fullName]
    }
    fieldValidators.current.delete(field.fullName)
  }, [])

  React.useEffect(
    function setupTouchedValidation() {
      if (!validateOnTouched) {
        return
      }
      return mobx.observe(formState.touched, change => {
        if (change.type === 'add') {
          enqueueFieldForValidation(change.newValue)
        }
      })
    },
    [enqueueFieldForValidation, formState, validateOnTouched],
  )

  React.useEffect(
    function runValidationWhenEnabled() {
      return mobx.reaction(
        () => formState.validationEnabled,
        enabled => {
          if (enabled) {
            lowPriorityValidation()
          }
        },
      )
    },
    [formState, lowPriorityValidation],
  )

  React.useEffect(
    function cleanupOnUnmount() {
      return () => {
        debouncedLowPriorityValidation.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        Object.values(changeDisposers.current).forEach(dis => dis())
      }
    },
    [debouncedLowPriorityValidation],
  )

  return { validateForm, addFieldForValidation, removeFieldFromValidation }
}

function getFieldValidator(
  validationSchema: TValidationSchema,
  field: XField,
  formState: XFormState<any>,
  ignoreMissing: boolean,
) {
  try {
    // non existing path throws hard error
    // https://github.com/jquense/yup/issues/599
    yup.reach(validationSchema, field.fullName)
    const schemaValidator = () => {
      return validationSchema
        .validateAt(field.fullName, formState.values)
        .then(() => undefined) // return undefined if there is no error
        .catch(err => {
          const [firstError] = collectYupErrors(err)
          const [, message] = firstError
          return message
        })
    }
    return schemaValidator
  } catch (err) {
    if (process.env.NODE_ENV === 'development' && !ignoreMissing) {
      // eslint-disable-next-line no-console
      console.warn(err.message)
    }
    return null
  }
}

// [path, message]
type TValidationErrorTuple = [string, string]

function collectYupErrors(
  yupError: TValidationError,
): Array<TValidationErrorTuple> {
  if (yupError.inner.length === 0) {
    return [[yupError.path, yupError.message]]
  }
  return yupError.inner.map(inner => {
    return [inner.path, inner.message] as TValidationErrorTuple
  })
}
