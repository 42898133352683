import { DETECTORS, LocaleResolver, TRANSFORMERS } from 'locales-detector'

export function detectLanguage(
  supportedLanguages: string[],
  fallbackLanguage: string,
) {
  const resolver = new LocaleResolver(
    [new DETECTORS.UrlDetector('lang'), new DETECTORS.NavigatorDetector()],
    [new TRANSFORMERS.LanguageOnlyTransformer()],
  )
  const languages: RoA<string> = resolver.getLocales()
  const supported = languages.find(loc => supportedLanguages.includes(loc))
  return supported || fallbackLanguage
}
