import React from 'react'

import { Pictogram, TPictogramProps } from './Pictogram'
import { getPictogramForIcon, TPictogramIcon } from './pictograms'

type TProps = NoChildren &
  Omit<TPictogramProps, 'pictogram'> & {
    icon: TPictogramIcon
  }

export const PictogramIcon: React.FC<TProps> = ({
  icon,
  ...pictogramProps
}) => {
  const pictogram = getPictogramForIcon(icon)

  if (!pictogram) {
    return null
  }

  return <Pictogram {...pictogramProps} pictogram={pictogram} />
}
