import React from 'react'
import invariant from 'tiny-invariant'

import { isFunction } from './utils'

export function handleSubmitInternal(e: React.FormEvent<HTMLFormElement>) {
  if (e && e.preventDefault && isFunction(e.preventDefault)) {
    e.preventDefault()
  }

  if (e && e.stopPropagation && isFunction(e.stopPropagation)) {
    e.stopPropagation()
  }

  // Warn if form submission is triggered by a <button> without a
  // specified `type` attribute during development. This mitigates
  // a common gotcha in forms with both reset and submit buttons,
  // where the dev forgets to add type="button" to the reset button.
  if (
    process.env.NODE_ENV === 'development' &&
    typeof document !== 'undefined'
  ) {
    // Safely get the active element (works with IE)
    const activeElement = getActiveElement()
    if (activeElement !== null && activeElement instanceof HTMLButtonElement) {
      invariant(
        !!(
          activeElement.attributes &&
          activeElement.attributes.getNamedItem('type')
        ),
        'You submitted a XForm form using a button with an unspecified `type` attribute.  Most browsers default button elements to `type="submit"`. If this is not a submit button, please add `type="button"`.',
      )
    }
  }
}

/**
 * Same as document.activeElement but wraps in a try-catch block. In IE it is
 * not safe to call document.activeElement if there is nothing focused.
 *
 * The activeElement will be null only if the document or document body is not
 * yet defined.
 *
 * @param {?Document} doc Defaults to current document.
 * @return {Element | null}
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/dom/getActiveElement.js
 */
export function getActiveElement(doc?: Document): Element | null {
  doc = doc || (typeof document !== 'undefined' ? document : undefined)
  if (typeof doc === 'undefined') {
    return null
  }
  try {
    return doc.activeElement || doc.body
  } catch (e) {
    return doc.body
  }
}
