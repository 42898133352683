import { useLocalStore } from 'mobx-react-lite'
import React from 'react'

import { getIn } from './utils'
import { XFieldError, XFieldName, XFieldState, XFieldTouched } from './xform.types'
import { useXFormCtx } from './XFormContext'

export function useXFieldState<TValue>(fieldName: XFieldName) {
  const ctx = useXFormCtx()

  const field = React.useMemo(() => {
    return ctx.api.getField(fieldName)
  }, [ctx.api, fieldName])

  const state: XFieldState<TValue> = useLocalStore(
    source => ({
      get name() {
        return source.fullName
      },
      get value() {
        return getIn(ctx.state.values, source.fullPath) as TValue
      },
      setValue(newValue: TValue) {
        const parent = getIn(ctx.state.values, source.parentPath)
        parent[source.baseName] = newValue
      },
      get touchedPure() {
        return ctx.state.touched.has(this.name)
      },
      get touched() {
        return ctx.state.wasSubmitted || this.touchedPure
      },
      setTouched(touched: XFieldTouched = true) {
        if (touched) {
          ctx.state.touched.add(this.name)
        } else {
          ctx.state.touched.delete(this.name)
        }
      },
      get error() {
        return ctx.state.errors.get(this.name)
      },
      setError(error: XFieldError) {
        if (error) {
          ctx.state.errors.set(this.name, error)
        } else {
          ctx.state.errors.delete(this.name)
        }
      },
      clearError() {
        ctx.state.errors.delete(this.name)
      },
      get hasError() {
        return this.error !== undefined
      },
      get isValid() {
        return !this.hasError
      },
    }),
    field,
  )

  return state
}
