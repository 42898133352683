import * as Sentry from '@sentry/browser'
import React from 'react'

export const useSentryScope = () => {
  const scopeRef = React.useRef(Sentry.getCurrentHub().pushScope())

  React.useEffect(() => {
    return () => {
      scopeRef.current.clear()
      Sentry.getCurrentHub().popScope()
    }
  })

  return scopeRef.current
}
