import React from 'react'
import * as yup from 'yup'

export type TYup = typeof yup
export type TValidationDefinition = yup.ObjectSchemaDefinition<any>
export type TValidationSchema = yup.ObjectSchema<any>
export type TValidationError = yup.ValidationError

export function isValidationError(err: any): err is TValidationError {
  return err.name === 'ValidationError'
}

type TDefineSchema<T extends object> = (
  yup: TYup,
) => yup.ObjectSchemaDefinition<T>

export function createObjectValidation<T extends object>(
  define: TDefineSchema<T>,
): yup.ObjectSchema<T> {
  return yup.object(define(yup))
}

export function useObjectValidation<T extends object>(
  define: TDefineSchema<T>,
) {
  const [schema] = React.useState(() => createObjectValidation(define))
  return schema
}

export function setValidationLocale(locale: yup.LocaleObject) {
  yup.setLocale(locale)
}

export { yup }
