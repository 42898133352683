import React from 'react'

import { useXField } from './useXField'
import { XFieldName, XFieldOptions } from './xform.types'

export function useXFieldForInput<TValue = string>(
  fieldName: XFieldName,
  options: XFieldOptions<TValue> = {},
) {
  const field = useXField(fieldName, options)
  const getInputProps = () => ({
    id: field.name,
    name: field.name,
    value: field.value === undefined || field.value === null ? '' : field.value,
    onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
      field.setValue(ev.target.value as any)
    },
    onBlur: () => {
      field.setTouched()
    },
  })
  return [field, getInputProps] as [typeof field, typeof getInputProps]
}
