import { debounce } from 'debounce'
import * as mobx from 'mobx'
import React from 'react'

import { XFormLogger, XFormState } from './xform.types'

const noop = () => ({})

const DEBOUNCE_INTERVAL = 300

export function useFormLogger(state: XFormState<any>, logger?: XFormLogger) {
  const loggerRef = React.useRef(logger || noop)

  React.useEffect(() => {
    const log = debounce(
      (values, errors) => loggerRef.current({ values, errors }),
      DEBOUNCE_INTERVAL,
    )
    return mobx.autorun(() => {
      log(
        mobx.toJS(state.values),
        state.errors.size === 0 ? 'none' : mobx.toJS(state.errors),
      )
    })
  }, [state])
}
