/// <reference types="@speedlo/types" />
import { useDebouncedCallback } from '@bit/speedlo.hooks.use-debounce'
import * as RM from 'ramda'
import React from 'react'

interface IOptions {
  onCodeAdd: (code: string) => Promise<IAddResult | undefined>
  onCodeErrors: (code: string, errors: RoA<string>) => void
  getValidatingMessage?: (code: string) => string
  autoSubmitAfterTimeout?: number
}

interface IAddResult {
  readonly addOrderPromoCode: {
    readonly userErrors: RoA<string>
  }
}

export function useOrderPromoCodes({
  onCodeAdd,
  onCodeErrors,
  getValidatingMessage = RM.identity,
  autoSubmitAfterTimeout = 2000,
}: IOptions) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isValidating, setValidating] = React.useState(false)

  const addCode = React.useCallback(async () => {
    if (!inputRef.current || inputRef.current.disabled) {
      return
    }
    const code = inputRef.current.value.trim()
    if (code.length === 0) {
      return
    }
    setValidating(true)
    inputRef.current.disabled = true
    inputRef.current.value = getValidatingMessage(code)
    try {
      let isSuccess = true
      const result = await onCodeAdd(code)
      if (result) {
        const { userErrors } = result.addOrderPromoCode
        if (userErrors.length > 0) {
          isSuccess = false
          onCodeErrors(code, userErrors)
        }
      } else {
        isSuccess = false
      }
      if (inputRef.current) {
        inputRef.current.value = ''
        if (!isSuccess) {
          inputRef.current.focus()
        }
      }
    } catch (err) {
      if (inputRef.current) {
        inputRef.current.value = code
      }
    } finally {
      setValidating(false)
      if (inputRef.current) {
        inputRef.current.disabled = false
        inputRef.current.focus()
      }
    }
  }, [getValidatingMessage, onCodeAdd, onCodeErrors])

  const [onChange] = useDebouncedCallback(addCode, autoSubmitAfterTimeout)

  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        addCode()
        ev.preventDefault()
      }
    },
    [addCode],
  )

  const inputProps = {
    autoComplete: 'off',
    disabled: isValidating,
    onChange,
    onKeyDown,
    onBlur: addCode,
    onPaste: addCode,
  }

  return { inputProps, inputRef, isValidating }
}
