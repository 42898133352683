import React from 'react'
import invariant from 'tiny-invariant'

import { XFormContext } from './xform.types'

export const PrivateXFormContext = React.createContext<any>(undefined)

export const XFormProvider = PrivateXFormContext.Provider

export function useXFormCtx<TValues>() {
  const ctx = React.useContext<XFormContext<TValues>>(PrivateXFormContext)

  invariant(
    !!ctx,
    `XForm context is undefined, please verify you are calling useXFormCtx() as child of a <XFormProvider> component.`,
  )

  return ctx
}

export function useXFormState<TValues>() {
  const ctx = useXFormCtx<TValues>()
  return ctx.state
}
