/// <reference types="@speedlo/types/react" />
import React from 'react'

const context = React.createContext(() => new Date())

/**
 * Simple hook providing a function that returns current Date.
 *
 * True power lies in utilizing Context and allowing to override
 * behavior in tests.
 */
export function useNow() {
  return React.useContext(context)
}

type TProviderProps = SomeChildren & {
  date: Date | (() => Date)
}

/**
 * Overrides useNow() either with specific date
 * or provide a getter function returning date
 */
export const NowProvider: React.FC<TProviderProps> = ({ date, children }) => {
  const getNow = typeof date === 'function' ? date : () => date
  return React.createElement(context.Provider, { value: getNow }, children)
}
