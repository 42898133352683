import React from 'react'
import styled from 'styled-components'

import { cn } from '../cn'
import { TPictogram } from './pictograms'

type TStyledProps = {
  size: number
  inline: boolean
}

export type TPictogramProps = NoChildren &
  Partial<TStyledProps> & {
    title?: string
    className?: string
    onClick?: () => void
    pictogram: TPictogram
  }

const NEW_ICONS_SIZE_COEF = 1.4

const StyledPictogram = styled.div<TStyledProps>`
  display: ${({ inline }) => (inline ? `inline-block` : `block`)};

  &,
  &::before {
    font-size: ${({ size }) => `${size}rem`};
  }

  &::before {
    /* this applies to the old black&white pictograms styled as one before pseudo-element */
    /* the margin would bring them more horizontally in line with the new ones */
    /* but lets leave that to the actual use-cases where it is required (leaving the style here as a reference) */
    /* margin-left: 0.25em; */
  }

  *::before {
    /* this applies to the new colored pictograms styled as multiple paths */
    /* these are for some reason a bit smaller (maybe bcs of the spaces around the image) */
    /* so this should make them look approx. the same size as the original black&white */

    /* this logic was moved into the component below, because Safari didn't work well */
    /* with various decimal values used as font-size in combination with em unit used for absolute positioning of the sub paths */
    /* leaving it here just for the reference */

    /* font-size: ${`${NEW_ICONS_SIZE_COEF}em`}; */
  }
`

export const Pictogram: React.FC<TPictogramProps> = React.memo(
  ({ size = 1, title, pictogram, onClick, className, inline = false }) => {
    // some icons require certain amount of sub-elements (named pathX) that hold its styling
    // if this pictogram should have some, make sure to render enough of them inside the actual flaticon div
    const renderPictogramPathElements = () => {
      if (!pictogram.pathCount) {
        return null
      }

      const pathArray = new Array(pictogram.pathCount).fill(0)

      return pathArray.map((item, index) => (
        <span key={index} className={`path${index + 1}`}></span>
      ))
    }

    const calculateIconSize = () => {
      const hasSomePaths = Boolean(pictogram.pathCount)

      if (hasSomePaths) {
        // if the icon has some paths, it's the new, colored one – these are a bit smaller so lets increase their size a bit
        // same logic was previously used in the Styled component above (moved here)
        // and for some reason, Safari breaks the positioning of multi-path icons
        //  if their size is set to anything but .0, .25, .5 or .75 value, hence the quarter rounding
        const rawSize = size * NEW_ICONS_SIZE_COEF
        const sizeRoundedToNearestQuarter = Math.round(rawSize * 4) / 4
        return sizeRoundedToNearestQuarter
      }

      return size
    }

    return (
      <StyledPictogram
        title={title}
        inline={inline}
        onClick={onClick}
        size={calculateIconSize()}
        className={cn(`pictogram flaticon-${pictogram.icon}`, className)}
      >
        {renderPictogramPathElements()}
      </StyledPictogram>
    )
  },
)
