/// <reference types="@speedlo/types/react" />
import { useLocalStore, useObserver } from 'mobx-react-lite'
import * as RM from 'ramda'
import React from 'react'

import { cn } from './cn'

export type TVamAgreement = {
  readonly id: string
  readonly name: string
  readonly description: string
  readonly isMandatory: boolean
  isConfirmed: boolean
}

export interface RenderVamOptions {
  AgreementBox: React.FC<IAgreementBoxProps>
  confirmedWrapperClass?: string
  descriptionCollapsedClass?: string
}

export interface IAgreementBoxProps {
  agreement: TVamAgreement
  isValid: boolean
  isCollapsed: boolean
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>
  titleNode: ReactNode
  descriptionNode: ReactNode
}

export const useVamAgreements = (agreements: RoA<TVamAgreement>) => {
  const state = useLocalStore(() => ({
    initialized: false,
    hiddenAgreements: new Set<ID>(),
    confirmedAgreements: new Set<ID>(),
    openDescriptions: new Set<ID>(),
    toggleConfirmed(agreementId: ID) {
      if (state.confirmedAgreements.has(agreementId)) {
        state.confirmedAgreements.delete(agreementId)
      } else {
        state.confirmedAgreements.add(agreementId)
      }
    },
    toggleDescription(agreementId: ID) {
      if (state.openDescriptions.has(agreementId)) {
        state.openDescriptions.delete(agreementId)
      } else {
        state.openDescriptions.add(agreementId)
      }
    },
  }))

  React.useEffect(() => {
    // initially all agreements are loaded as not confirmed
    // as user inputs data, new set of agreements can be received
    // with some agreements confirmed from previous orders
    state.hiddenAgreements.clear()
    agreements
      .filter(RM.propEq('isConfirmed', true))
      .map(RM.prop('id'))
      .forEach(agreementId => {
        // hide confirmed from display
        state.hiddenAgreements.add(agreementId)
        // but remember it's been confirmed
        state.confirmedAgreements.add(agreementId)
      })
    state.initialized = true
  }, [agreements, state])

  const renderVamAgreements = ({
    AgreementBox,
    confirmedWrapperClass = 'confirmed',
    descriptionCollapsedClass = 'collapsed',
  }: RenderVamOptions) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => (
      <>
        {agreements.map(agreement => {
          const agreementId = agreement.id
          if (state.hiddenAgreements.has(agreementId)) {
            return null
          }
          const isCollapsed = !state.openDescriptions.has(agreementId)
          const isConfirmed = state.confirmedAgreements.has(agreementId)

          return (
            <div
              key={agreement.id}
              className={isConfirmed ? confirmedWrapperClass : ''}
            >
              <AgreementBox
                agreement={agreement}
                isValid={!agreement.isMandatory || isConfirmed}
                isCollapsed={isCollapsed}
                titleNode={
                  <span dangerouslySetInnerHTML={{ __html: agreement.name }} />
                }
                descriptionNode={
                  <div
                    className={cn({
                      [descriptionCollapsedClass]: isCollapsed,
                    })}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: agreement.description,
                      }}
                    />
                  </div>
                }
                inputProps={{
                  checked: isConfirmed,
                  onChange: () => state.toggleConfirmed(agreement.id),
                  required: agreement.isMandatory,
                }}
                buttonProps={{
                  onClick: () => state.toggleDescription(agreement.id),
                }}
              />
            </div>
          )
        })}
      </>
    ))

  return { state, renderVamAgreements }
}
