import isEqual from 'fast-deep-equal'
import * as mobx from 'mobx'
import { useLocalStore } from 'mobx-react-lite'
import React from 'react'

import { handleSubmitInternal } from './internals'
import { useFormLogger } from './useFormLogger'
import { useFormValidation } from './useFormValidation'
import { fromPath, toPath } from './utils'
import { XFieldPath, XFieldRegistry, XFormApi, XFormConfig, XFormHandlers, XFormState, XFormValues } from './xform.types'

export function useXForm<TValues = XFormValues>(config: XFormConfig<TValues>) {
  const {
    onSubmit,
    onSubmitFailed,
    onReset,
    validationSchema,
    validateOnChange = true,
    validateOnTouched = true,
    initialValidationEnabled = true,
    lowPriorityDebounceIntervalMs = 250,
    ignoreUnknownFieldsInValidation = false,
  } = config

  const initialValues = React.useRef(config.initialValues)

  const state: XFormState<TValues> = useLocalStore(() => ({
    initialValues: initialValues.current,
    values: initialValues.current,
    errors: new Map(),
    touched: new Set(),
    isSubmitting: false,
    isValidating: false,
    submitCount: 0,
    pristineValues: initialValues.current,
    validationEnabled: initialValidationEnabled,
    get isDirty() {
      return !isEqual(this.pristineValues, this.values)
    },
    get isTouched() {
      return this.touched.size > 0
    },
    get isValid() {
      return this.errors.size === 0
    },
    get wasSubmitted() {
      return this.submitCount > 0
    },
  }))

  const [fieldRegistry] = React.useState<XFieldRegistry>(() =>
    mobx.observable.map(),
  )

  const {
    validateForm,
    addFieldForValidation,
    removeFieldFromValidation,
  } = useFormValidation({
    formState: state,
    fieldRegistry,
    validationSchema,
    validateOnChange,
    validateOnTouched,
    lowPriorityDebounceIntervalMs,
    ignoreUnknownFieldsInValidation,
  })

  const mergeValues = React.useCallback(
    mobx.action((values: Partial<TValues>) => {
      mobx.set(state.values, values)
    }),
    [state],
  )

  const submitForm = React.useCallback(async () => {
    state.submitCount += 1
    await validateForm()
    if (state.isValid) {
      state.isSubmitting = true
      return Promise.resolve(onSubmit(state.values)).finally(() => {
        state.isSubmitting = false
      })
    } else if (onSubmitFailed) {
      onSubmitFailed(state.values, state.errors)
    } else {
      console.warn('Validation failed', {
        values: mobx.toJS(state.values),
        errors: mobx.toJS(state.errors),
      })
    }
  }, [onSubmit, onSubmitFailed, state, validateForm])

  const resetForm = React.useCallback(() => {
    mergeValues(initialValues.current)
    state.pristineValues = initialValues.current
    state.errors.clear()
    state.touched.clear()
    state.isSubmitting = false
    state.isValidating = false
    state.submitCount = 0
    if (initialValidationEnabled === false) {
      state.validationEnabled = false
    }
    if (onReset) {
      onReset()
    }
  }, [mergeValues, initialValidationEnabled, onReset, state])

  const api: XFormApi<TValues> = React.useMemo(
    () => ({
      submitForm,
      resetForm,
      mergeValues,
      validateForm,
      enableValidation: () => (state.validationEnabled = true),
      disableValidation: () => (state.validationEnabled = false),
      registerField: (field, options) => {
        if (fieldRegistry.has(field.fullName)) {
          // eslint-disable-next-line no-console
          console.error(
            `XForm: Field "${field.fullName}" cannot be registered multiple times in a single form. The \`useXFieldState\` hook can be used for state access.`,
          )
          return
        }
        fieldRegistry.set(field.fullName, {
          field,
          ...options,
        })
        addFieldForValidation(field)
      },
      unregisterField: field => {
        removeFieldFromValidation(field)
        fieldRegistry.delete(field.fullName)
      },
      getField: fieldName => {
        let fullPath: XFieldPath
        if (Array.isArray(fieldName)) {
          fullPath = fieldName
        } else {
          fullPath = toPath(fieldName)
        }

        const parentPath = fullPath.slice(0, -1)
        const [baseName] = fullPath.slice(-1) as string[]

        return {
          fullName: fromPath(fullPath),
          fullPath,
          baseName,
          parentPath,
        }
      },
    }),
    [
      addFieldForValidation,
      fieldRegistry,
      mergeValues,
      removeFieldFromValidation,
      resetForm,
      state,
      submitForm,
      validateForm,
    ],
  )

  const handlers: XFormHandlers = {
    handleSubmit: React.useCallback(
      (ev: React.FormEvent<HTMLFormElement>) => {
        handleSubmitInternal(ev)
        api.submitForm()
      },
      [api],
    ),
    handleReset: React.useCallback(() => {
      api.resetForm()
    }, [api]),
  }

  useFormLogger(state, config.onFormChanges)

  return { state, handlers, api }
}
