export const symbolPictograms = [
  { icon: 'chevron-up' },
  { icon: 'mark' },
  { icon: 'delete' },
] as const

/**
 * general icons used in the apps (eg. cart or credit card icons)
 */
export const generalPurposePictograms = [
  ...symbolPictograms,
  { icon: 'money' },
  { icon: 'credit-card' },
  { icon: 'black-and-white-credit-cards' },
  { icon: 'technology' },
  { icon: 'transport-1' },
  { icon: 'sign' },
  { icon: 'transport' },
  { icon: 'edit' },
  { icon: 'restaurant' },
  { icon: 'clock' },
  { icon: 'cart' },
  { icon: 'signs' },
  { icon: 'gps' },
  { icon: 'commerce-2' },
  { icon: 'thumb_up' },
] as const

export const warePictogramsBlackWhite = [
  { icon: 'food-30' },
  { icon: 'fruit-2' },
  { icon: 'food-29' },
  { icon: 'food-291' },
  { icon: 'food-27' },
  { icon: 'food-26' },
  { icon: 'food-25' },
  { icon: 'drink-5' },
  { icon: 'cheese' },
  { icon: 'food-24' },
  { icon: 'strawberry' },
  { icon: 'drink-4' },
  { icon: 'drink-3' },
  { icon: 'cup-2' },
  { icon: 'drink-2' },
  { icon: 'food-23' },
  { icon: 'drink-1' },
  { icon: 'drink' },
  { icon: 'fruit-1' },
  { icon: 'food-22' },
  { icon: 'food-21' },
  { icon: 'food-20' },
  { icon: 'food-19' },
  { icon: 'hot-pepper-outline' },
  { icon: 'smoking-barbecue' },
  { icon: 'cardboard-box-with-chopsticks' },
  { icon: 'meat-pie' },
  { icon: 'nature' },
  { icon: 'sausage' },
  { icon: 'hot-dog' },
  { icon: 'food-18' },
  { icon: 'food-17' },
  { icon: 'food-16' },
  { icon: 'fruit' },
  { icon: 'food-15' },
  { icon: 'food-14' },
  { icon: 'food-13' },
  { icon: 'food-12' },
  { icon: 'food' },
  { icon: 'cup-1' },
  { icon: 'whisky' },
  { icon: 'food-11' },
  { icon: 'soup' },
  { icon: 'noodles-1' },
  { icon: 'ramen' },
  { icon: 'rice' },
  { icon: 'food-10' },
  { icon: 'food-9' },
  { icon: 'spaghetti' },
  { icon: 'food-8' },
  { icon: 'food-7' },
  { icon: 'piece-of-cake' },
  { icon: 'beer' },
  { icon: 'marshmallow' },
  { icon: 'cake' },
  { icon: 'split' },
  { icon: 'ice-cream' },
  { icon: 'cookie' },
  { icon: 'doughnut' },
  { icon: 'cupcake' },
  { icon: 'kebab' },
  { icon: 'food-6' },
  { icon: 'beans' },
  { icon: 'noodles' },
  { icon: 'fish' },
  { icon: 'steak' },
  { icon: 'food-5' },
  { icon: 'food-4' },
  { icon: 'food-3' },
  { icon: 'fondue' },
  { icon: 'skewer' },
  { icon: 'pizza' },
  { icon: 'food-2' },
  { icon: 'shrimp' },
  { icon: 'food-1' },
  { icon: 'cup' },
  { icon: 'berry' },
  { icon: 'ball_basketball' },
  { icon: 'ball_football' },
  { icon: 'corkscrew' },
  { icon: 'cutlery' },
  { icon: 'farfalle' },
  { icon: 'food_and_drink' },
  { icon: 'fusilli' },
  { icon: 'half_of_pizza' },
  { icon: 'ice_hockey' },
  { icon: 'milk' },
  { icon: 'milk_products' },
  { icon: 'pastry' },
  { icon: 'penne' },
  { icon: 'pizzabox' },
  { icon: 'pretzel' },
  { icon: 'wine' },
] as const

export const warePictogramsColored = [
  { icon: 'grapes', pathCount: 32 },
  { icon: 'melon', pathCount: 8 },
  { icon: 'watermelon', pathCount: 14 },
  { icon: 'tangerine', pathCount: 12 },
  { icon: 'lemon', pathCount: 6 },
  { icon: 'banana', pathCount: 7 },
  { icon: 'pineapple', pathCount: 15 },
  { icon: 'mango', pathCount: 8 },
  { icon: 'red_apple', pathCount: 9 },
  { icon: 'green_apple', pathCount: 11 },
  { icon: 'pear', pathCount: 5 },
  { icon: 'peach', pathCount: 16 },
  { icon: 'cherries', pathCount: 12 },
  { icon: 'strawberry-color', pathCount: 33 },
  { icon: 'kiwi_fruit', pathCount: 11 },
  { icon: 'tomato', pathCount: 7 },
  { icon: 'coconut', pathCount: 13 },
  { icon: 'avocado', pathCount: 6 },
  { icon: 'eggplant', pathCount: 13 },
  { icon: 'potato', pathCount: 6 },
  { icon: 'carrot', pathCount: 11 },
  { icon: 'ear_of_corn', pathCount: 10 },
  { icon: 'hot_pepper', pathCount: 12 },
  { icon: 'cucumber', pathCount: 3 },
  { icon: 'leafy_green', pathCount: 8 },
  { icon: 'broccoli', pathCount: 14 },
  { icon: 'garlic', pathCount: 27 },
  { icon: 'onion', pathCount: 9 },
  { icon: 'mushroom', pathCount: 14 },
  { icon: 'peanuts', pathCount: 5 },
  { icon: 'chestnut', pathCount: 10 },
  { icon: 'bread', pathCount: 4 },
  { icon: 'croissant', pathCount: 14 },
  { icon: 'baguette_bread', pathCount: 6 },
  { icon: 'pretzel-color', pathCount: 8 },
  { icon: 'bagel', pathCount: 22 },
  { icon: 'pancakes', pathCount: 17 },
  { icon: 'waffle', pathCount: 21 },
  { icon: 'cheese_wedge', pathCount: 15 },
  { icon: 'meat_on_bone', pathCount: 9 },
  { icon: 'poultry_leg', pathCount: 4 },
  { icon: 'cut_of_meat', pathCount: 6 },
  { icon: 'bacon', pathCount: 6 },
  { icon: 'hamburger', pathCount: 22 },
  { icon: 'french_fries', pathCount: 20 },
  { icon: 'pizza-color', pathCount: 10 },
  { icon: 'hot_dog', pathCount: 10 },
  { icon: 'sandwich', pathCount: 16 },
  { icon: 'taco', pathCount: 12 },
  { icon: 'burrito', pathCount: 7 },
  { icon: 'stuffed_flatbread', pathCount: 20 },
  { icon: 'falafel', pathCount: 14 },
  { icon: 'cooking', pathCount: 8 },
  { icon: 'shallow_pan_of_food', pathCount: 27 },
  { icon: 'pot_of_food', pathCount: 7 },
  { icon: 'bowl_with_spoon', pathCount: 5 },
  { icon: 'green_salad', pathCount: 12 },
  { icon: 'popcorn', pathCount: 6 },
  { icon: 'butter', pathCount: 7 },
  { icon: 'salt', pathCount: 6 },
  { icon: 'canned_food', pathCount: 14 },
  { icon: 'bento_box', pathCount: 25 },
  { icon: 'rice_cracker', pathCount: 5 },
  { icon: 'rice_ball', pathCount: 5 },
  { icon: 'cooked_rice', pathCount: 5 },
  { icon: 'curry_rice', pathCount: 11 },
  { icon: 'steaming_bowl', pathCount: 10 },
  { icon: 'spaghetti-color', pathCount: 16 },
  { icon: 'roasted_sweet_potato', pathCount: 9 },
  { icon: 'oden', pathCount: 10 },
  { icon: 'sushi', pathCount: 4 },
  { icon: 'fried_shrimp', pathCount: 4 },
  { icon: 'fish_cake_with_swirl', pathCount: 23 },
  { icon: 'moon_cake', pathCount: 12 },
  { icon: 'dango', pathCount: 10 },
  { icon: 'dumpling', pathCount: 6 },
  { icon: 'fortune_cookie', pathCount: 5 },
  { icon: 'takeout_box', pathCount: 22 },
  { icon: 'oyster', pathCount: 10 },
  { icon: 'soft_ice_cream', pathCount: 14 },
  { icon: 'shaved_ice', pathCount: 12 },
  { icon: 'ice_cream', pathCount: 12 },
  { icon: 'doughnut-color', pathCount: 22 },
  { icon: 'cookie-color', pathCount: 16 },
  { icon: 'birthday_cake', pathCount: 12 },
  { icon: 'shortcake', pathCount: 11 },
  { icon: 'cupcake-color', pathCount: 37 },
  { icon: 'pie', pathCount: 7 },
  { icon: 'chocolate_bar', pathCount: 8 },
  { icon: 'candy', pathCount: 8 },
  { icon: 'lollipop', pathCount: 14 },
  { icon: 'custard', pathCount: 13 },
  { icon: 'honey_pot', pathCount: 12 },
  { icon: 'baby_bottle', pathCount: 9 },
  { icon: 'glass_of_milk', pathCount: 4 },
  { icon: 'hot_beverage', pathCount: 5 },
  { icon: 'teacup_without_handle', pathCount: 6 },
  { icon: 'sake', pathCount: 13 },
  { icon: 'bottle_with_popping_cork', pathCount: 16 },
  { icon: 'wine_glass', pathCount: 9 },
  { icon: 'cocktail_glass', pathCount: 9 },
  { icon: 'tropical_drink', pathCount: 9 },
  { icon: 'beer_mug', pathCount: 7 },
  { icon: 'clinking_beer_mugs', pathCount: 15 },
  { icon: 'clinking_glasses', pathCount: 12 },
  { icon: 'tumbler_glass', pathCount: 8 },
  { icon: 'cup_with_straw', pathCount: 5 },
  { icon: 'beverage_box', pathCount: 12 },
  { icon: 'mate', pathCount: 7 },
  { icon: 'ice', pathCount: 7 },
  { icon: 'chopsticks', pathCount: 4 },
  { icon: 'fork_and_knife_with_plate', pathCount: 9 },
  { icon: 'fork_and_knife', pathCount: 5 },
  { icon: 'spoon', pathCount: 2 },
] as const

/**
 * use for ware categories
 */
export const warePictograms = [
  ...warePictogramsColored,
  ...warePictogramsBlackWhite,
]

/**
 * all supported icons
 */
export const pictograms = [...generalPurposePictograms, ...warePictograms]
const pictogramMap = pictograms.reduce((map, pictogram) => {
  if (map.has(pictogram.icon)) {
    throw new Error(
      'There are some duplicities in Flaticon pictogram font in @speedlo/ui!',
    )
  }

  map.set(pictogram.icon, pictogram)

  return map
}, new Map<TPictogramIcon, TPictogram>())

export type TPictogramIcon = typeof pictograms[number]['icon']
export type TPictogram = {
  icon: TPictogramIcon
  pathCount?: number
}

/**
 * use this if you don’t want to retype string -> TPictogramIcon & do not mind not finding the icon
 */
export const getPictogramForLabel = (pictogramLabel: Maybe<string>) => {
  if (!pictogramLabel) {
    return null
  }

  const foundPictogram = pictogramMap.get(pictogramLabel as TPictogramIcon)
  return foundPictogram || null
}

/**
 * use this if you want to get TS intellisense for supported icons
 */
export const getPictogramForIcon = (pictogramIcon: Maybe<TPictogramIcon>) => {
  return getPictogramForLabel(pictogramIcon)
}
