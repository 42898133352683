import * as Sentry from '@sentry/browser'
import { getVersionManifest } from '@speedlo/tools/dist/versionManifest'
import React from 'react'

export const useSentryInit = (dsn: string, options?: Sentry.BrowserOptions) => {
  const { sha1, name, stage } = getVersionManifest()

  const isInit = React.useRef(false)

  React.useEffect(() => {
    if (!dsn || isInit.current) {
      return
    }
    Sentry.init({
      dsn,
      environment: stage,
      debug: stage === 'dev',
      release: `${sha1}-${name}`,
      ...options,
    })
    isInit.current = true
  }, [dsn, sha1, name, stage, options])
}
