import { getFlag } from '@speedlo/assets'
import React from 'react'
import styled from 'styled-components'

import { cn } from '../cn'
import { ChevronLeft } from './ChevronLeft'

interface StyledProps {
  flagCount: number
  backgroundColor: string
  arrowColor: string
}

interface IProps {
  className?: string
  availableLanguages: RoA<string>
  selectedLanguage: string
  onChange: (language: string) => void
  backgroundColor?: string
  arrowColor?: string
  formatLanguage?: (language: string) => string
}

const pickerSizes = {
  gap: '10px',
  flagWidth: '2em',
  arrowWidth: '1em',
}

// arrow + gap + flag
const collapsedWidth = () =>
  `calc(${pickerSizes.arrowWidth} + ${pickerSizes.gap} + ${pickerSizes.flagWidth})`

// arrow + flagCount * (gap + flag)
const expandedWidth = (flagCount: number) =>
  `calc(${pickerSizes.arrowWidth} + ${flagCount} *  (${pickerSizes.gap} + ${pickerSizes.flagWidth}))`

const StyledLanguagePicker = styled.div`
  ${(props: StyledProps) => ``}

  padding: 10px;
  background: ${({ backgroundColor }) => `${backgroundColor}AA`};
  transition: background 0.3s;

  .wrapper {
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    grid-gap: ${pickerSizes.gap};
    overflow: hidden;
    width: ${collapsedWidth()};
    transition: width 0.3s;

    .arrow {
      width: ${pickerSizes.arrowWidth};
      height: 100%;
      order: -2;
      color: ${({ arrowColor }) => arrowColor};

      path {
        transition: d 0.3s;
      }
    }

    > img {
      height: 1.2em;
      width: ${pickerSizes.flagWidth};
    }

    .selected {
      order: -1 !important;
    }
  }

  &.expanded {
    background: ${({ backgroundColor }) => `${backgroundColor}E8`};

    .wrapper {
      width: ${({ flagCount }) => expandedWidth(flagCount)};

      .arrow path {
        d: path('M1,1 5,5 1,9');
      }
    }
  }
`

export const LanguagePicker: React.FC<IProps> = ({
  className,
  availableLanguages,
  selectedLanguage,
  onChange: selectLanguage,
  backgroundColor = '#FFF',
  arrowColor = '#000',
  formatLanguage = lang => lang.toLowerCase(),
}) => {
  const [expanded, setExpand] = React.useState(false)

  if (availableLanguages.length <= 1) {
    return null
  }

  const onFlagClick = (language: string) => {
    if (expanded) {
      selectLanguage(language)
    }

    setExpand(prevVal => !prevVal)
  }

  const toggleExpand = () => {
    setExpand(prevValue => !prevValue)
  }

  const isSelected = (language: string) =>
    formatLanguage(selectedLanguage) === language

  const renderFlag = (language: string, index: number) => {
    const formattedLang = formatLanguage(language)
    return (
      <img
        key={formattedLang}
        src={getFlag(formattedLang)}
        style={{ order: index }}
        onClick={() => onFlagClick(formattedLang)}
        className={cn({
          selected: isSelected(formattedLang),
        })}
      />
    )
  }

  return (
    <StyledLanguagePicker
      className={cn(className, { expanded })}
      flagCount={availableLanguages.length}
      backgroundColor={backgroundColor}
      arrowColor={arrowColor}
    >
      <div className="wrapper">
        <ChevronLeft onClick={toggleExpand} className="arrow" />
        {availableLanguages.map(renderFlag)}
      </div>
    </StyledLanguagePicker>
  )
}
