import React from 'react'
import styled from 'styled-components'

interface Props {
  onClick: () => void
  className?: string
}

const StyledChevronLeft = styled.svg`
  stroke: currentColor;
  stroke-width: 2;
  width: 2em;
  height: 2em;
  fill: none;
`

export const ChevronLeft: React.FC<Props> = ({ onClick, className }) => {
  return (
    <StyledChevronLeft
      viewBox="0 0 5 10"
      onClick={onClick}
      className={className}
    >
      <path d="M5,1 1,5 5,9" />
    </StyledChevronLeft>
  )
}
