import React from 'react'

import { useXFieldState } from './useXFieldState'
import { XFieldName, XFieldOptions } from './xform.types'
import { useXFormCtx } from './XFormContext'

export function useXField<TValue>(
  fieldName: XFieldName,
  options: XFieldOptions<TValue> = {},
) {
  const ctx = useXFormCtx()
  const field = useXFieldState<TValue>(fieldName)

  const { validate } = options

  React.useEffect(
    function handleFieldRegistration() {
      const field = ctx.api.getField(fieldName)
      ctx.api.registerField(field, { validate })
      return () => {
        ctx.api.unregisterField(field)
      }
    },
    [validate, fieldName, ctx.api],
  )
  // cannot use full options as dep as it's likely to be changing on each render
  // and would cause re-registering field every time

  return field
}
